import React from "react"
import {Route, Routes} from "react-router-dom"
import URedirect from "./uRedirect"
import UPromo from "./uPromo"
import ULk from "./uLk"
import "./App.css"
import {urlAlert} from 'api-methods'

function App() {

    let pn = (document.location.pathname + "").split('/link/')
    if (!!pn[1]) {
        if (([6,7]).indexOf(pn[1].length) === -1) {
            urlAlert('length').then()
        } else if (pn[1].replace(/^[A-Za-z0-9]+/g, "") !== "") {
            urlAlert('char').then()
        }
    }

    return <Routes>
        <Route path="/instruction/*" element={<URedirect title={"Инструкция"} />} />
        <Route path="/instruction" element={<URedirect title={"Инструкция"} />} />
        <Route path="/kis3" element={<UPromo />} />
        <Route path="/lk/*" element={<ULk />} />
        <Route path="/lk" element={<ULk />} />
        <Route path="/" element={<div>404</div>} />
    </Routes>
}

export default App
